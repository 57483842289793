<ft-modal-wrapper>
    <ft-touchpoint-header [name]="'Planner flow'" [activeToggleEnabled]="false">
    </ft-touchpoint-header>

    <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms"
                   [(selectedIndex)]="selectedIndex">
        <mat-tab label="Messaging">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'DiscoveryWrite'" [hide]="false">
                    <ft-snapshot-form [showCounter]="false" [loading$]="isLoading$" [commitSuccess$]="commitSuccess$"
                                      (commit)="commitMessagingTab($event)">
                        <section class="ft2-block -small">
                            <div class="ft2-container">
                                <form ngForm #form="ngForm">
                                    <ng-container *ngIf="messaging">
                                        <ng-container *ngIf="integration$ | async | clone as integration">
                                            <div class="row">
                                                <div class="col-4 mb-sm-2 col-md-12">
                                                    <p class="solid-text medium-text">
                                                        Headcount
                                                    </p>
                                                    <ng-container *ngIf="languages$ | async as languages">
                                                        <ft-language-preview-panel [languages]="languages"
                                                                                   *ngIf="languages.length > 1"
                                                                                   [selectedLanguage]="selectedPreviewLanguage"
                                                                                   (previewLanguageChange)="onPreviewLanguageChange($event)"
                                                                                   [simplified]="true">
                                                        </ft-language-preview-panel>
                                                    </ng-container>
                                                </div>
                                                <div class="col-8 col-md-12">
                                                    <ft-multiple-textarea-editor title="Guest policy message"
                                                                                 summary="This optional message will be displayed when your audience is asked how many adults and kids are traveling with them."
                                                                                 name="headcountStepDisclosure"
                                                                                 [fields]="[{ name: 'headcountStepDisclosure', label: '', languageName: 'languageName' }]"
                                                                                 [validationDefinition]="[{required: false, maxLength: 300, type: 'text'}]"
                                                                                 [(ngModel)]="messaging">
                                                        <ft-edit-panel label="Guest policy message"
                                                                       [loading]="isLoading$ | async">
                                                            <div
                                                                [innerHTML]="(messaging | languagePreview: 'headcountStepDisclosure' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                                        </ft-edit-panel>
                                                    </ft-multiple-textarea-editor>
                                                </div>
                                            </div>
                                            <hr class="mb-6">
                                            <div class="row">
                                                <div class="col-4 mb-sm-2 col-md-12">
                                                    <p class="solid-text medium-text">
                                                        Unveil
                                                    </p>
                                                </div>
                                                <div class="col-8 col-md-12">
                                                    <ft-multiple-textarea-editor title="Greeting message"
                                                                                 summary="This greeting will be displayed as a quote from the communication point person when we ask your audience to reveal who they are. This is a great way to add your own personality to the greeting. If left blank, we'll display a default message."
                                                                                 name="introGreetingText"
                                                                                 [tokens]="messageTokens"
                                                                                 [maxlength]="300"
                                                                                 [required]="false"
                                                                                 [fields]="[{ name: 'introGreetingText', label: '', languageName: 'languageName' }]"
                                                                                 [(ngModel)]="messaging">
                                                        <ft-edit-panel label="Greeting message"
                                                                       [loading]="isLoading$ | async">
                                                            <div
                                                                [innerHTML]="(messaging | languagePreview: 'introGreetingText' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                                        </ft-edit-panel>
                                                    </ft-multiple-textarea-editor>
                                                    <ft-html-editor title="Reason message"
                                                                    summary="This message is displayed to audiences who are planning. You can add a custom message to it."
                                                                    name="unveilReasonMessage"
                                                                    [tokens]="messageTokens"
                                                                    [visibleFormats]="['bold', 'italic', 'underline', 'link']"
                                                                    [maxlength]="1000"
                                                                    [required]="false"
                                                                    [fields]="[{ name: 'unveilReasonMessage', label: '', languageName: 'languageName' }]"
                                                                    [(ngModel)]="messaging">
                                                        <ft-edit-panel label="Reason message"
                                                                       [loading]="isLoading$ | async">
                                                            <div
                                                                [innerHTML]="(messaging | languagePreview: 'unveilReasonMessage' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                                        </ft-edit-panel>
                                                    </ft-html-editor>
                                                </div>
                                            </div>
                                            <hr class="mb-6">
                                            <div class="row">
                                                <div class="col-4 mb-sm-2 col-md-12">
                                                    <p class="solid-text medium-text">
                                                        Additional consent
                                                    </p>
                                                </div>
                                                <div class="col-8 col-md-12">
                                                    <ft-toggle-editor title="Additional consent settings"
                                                                      label="Status"
                                                                      summary="Turn this feature on if you want to include an opt-in checkbox with a custom consent message."
                                                                      [(ngModel)]="integration.isAdditionalConsentEnabled"
                                                                      name="isAdditionalConsentEnabled">
                                                        <ft-edit-panel label="Additional consent settings"
                                                                       [loading]="isLoading$ | async">
                                                            <div>
                                                                {{ integration.isAdditionalConsentEnabled | ftOnOffToggle }}
                                                            </div>
                                                        </ft-edit-panel>
                                                    </ft-toggle-editor>
                                                    <div [hidden]="!integration.isAdditionalConsentEnabled">
                                                        <ft-html-editor title="Consent message"
                                                                        name="consentMessage"
                                                                        summary="Your consent message will display with an opt-in checkbox."
                                                                        [tokens]="consentMessageTokens"
                                                                        [visibleFormats]="['bold', 'italic', 'underline', 'link']"
                                                                        [maxlength]="1000"
                                                                        [required]="true"
                                                                        [fields]="[{ name: 'consentMessage', label: '', languageName: 'languageName' }]"
                                                                        [(ngModel)]="messaging">
                                                            <ft-edit-panel label="Consent message"
                                                                           [loading]="isLoading$ | async">
                                                                <div
                                                                    [innerHTML]="(messaging | languagePreview: 'consentMessage' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                                            </ft-edit-panel>
                                                        </ft-html-editor>
                                                        <ft-input-editor title="Consent tags"
                                                                         summary="Provide a tag for tracking. You can add multiple tags separated with a comma."
                                                                         name="consentTags"
                                                                         placeholder="tag-1, tag-2, tag-3"
                                                                         [(ngModel)]="integration.consentTags">
                                                            <ft-edit-panel label="Consent tags"
                                                                           [loading]="isLoading$ | async">
                                                                <div [innerHTML]="integration.consentTags | ftPlaceholder">
                                                                </div>
                                                            </ft-edit-panel>
                                                        </ft-input-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="mb-6">
                                            <div class="row">
                                                <div class="col-4 mb-sm-2 col-md-12">
                                                    <p class="solid-text medium-text">
                                                        Property detail
                                                    </p>
                                                </div>
                                                <div class="col-8 col-md-12">
                                                    <ft-html-editor title="Aspirational pitch message"
                                                                    summary="This message will be displayed as a quote from the communication point person when we showcase your property on collaborating Discovery websites. This is a great way to add your own personality to your pitch message. If left blank, we'll display a default message."
                                                                    name="brandWelcomeText"
                                                                    [maxlength]="450"
                                                                    [required]="false"
                                                                    [fields]="[{ name: 'brandWelcomeText', label: '', languageName: 'languageName' }]"
                                                                    [(ngModel)]="messaging">
                                                        <ft-edit-panel label="Aspirational pitch message">
                                                            <div
                                                                [innerHTML]="(messaging | languagePreview: 'brandWelcomeText' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                                        </ft-edit-panel>
                                                    </ft-html-editor>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </form>
                            </div>
                        </section>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Styling">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'DiscoveryWrite'" [hide]="false">
                    <ft-snapshot-form (commit)="commit({styling: $event})" [loading$]="isLoading$"
                                      [commitSuccess$]="commitSuccess$">
                        <form ngForm>
                            <ng-container *ngIf="stylingViewModel$ | async | clone as data">
                                <section class="ft2-block -small">
                                    <div class="ft2-container">
<!--                                        <ft-select-editor title="Theme"-->
<!--                                                          [(ngModel)]="data.plannerFlowTheme"-->
<!--                                                          [placeholder]="'Select theme'"-->
<!--                                                          name="plannerFlowTheme"-->
<!--                                                          [bindLabel]="'name'"-->
<!--                                                          [bindValue]="'value'"-->
<!--                                                          [options]="plannerFlowThemeOptions$ | async">-->
<!--                                            <ft-edit-panel label="Theme">-->
<!--                                                <ng-container *ngFor="let theme of plannerFlowThemeOptions$ | async">-->
<!--                                                    <ng-container-->
<!--                                                        *ngIf="data?.plannerFlowTheme == theme?.value">-->
<!--                                                        {{ theme?.name | ftPlaceholder }}-->
<!--                                                    </ng-container>-->
<!--                                                </ng-container>-->
<!--                                            </ft-edit-panel>-->
<!--                                        </ft-select-editor>-->
                                        <ft-code-editor [replaceInvalidSymbols]="true" [language]="'css'" [title]="'CSS'" name="plannerFlowCss"
                                                        [(ngModel)]="data.plannerFlowCss">
                                            <ft-edit-panel label="CSS" [loading]="isLoading$ | async"
                                                           [error]="error$ | async">
                                                <div class="col-8"
                                                     [innerHTML]="data.plannerFlowCss | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-code-editor>
                                    </div>
                                </section>
                            </ng-container>
                        </form>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Integration">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'DiscoveryWrite'" [hide]="false">
                    <ft-snapshot-form [loading$]="isLoading$" [commitSuccess$]="commitSuccess$"
                                      (commit)="commit({integration: $event})">
                        <section class="ft2-block -small">
                            <div class="ft2-container">
                                <form ngForm>
                                    <ng-container *ngIf="integration$ | async | clone as data">
                                        <ft-select-editor title="Headcount step"
                                                          [(ngModel)]="data.isHeadcountStepEnabled"
                                                          name="isHeadcountStepEnabled"
                                                          [options]="discoveryPropertySettingOptions">
                                            <ft-edit-panel [disabled]="true"
                                                           *ngIf="(isFlightBookingOptionActive$ | async)"
                                                           summary="Headcount step is turned On because your platform is configured with flight settings."
                                                           label="Headcount step">
                                                <ng-container *ngFor="let item of discoveryPropertySettingOptions">
                                                    <div *ngIf="item.value === data.isHeadcountStepEnabled" [innerHTML]="item.label | ftPlaceholder">
                                                    </div>
                                                </ng-container>
                                            </ft-edit-panel>
                                            <ft-edit-panel *ngIf="!(isFlightBookingOptionActive$ | async)"
                                                           label="Headcount step">
                                                <ng-container *ngFor="let item of discoveryPropertySettingOptions">
                                                    <div *ngIf="item.value === data.isHeadcountStepEnabled" [innerHTML]="item.label | ftPlaceholder">
                                                    </div>
                                                </ng-container>
                                            </ft-edit-panel>
                                        </ft-select-editor>
                                        <ft-select-editor title="Flight steps"
                                                          *ngIf="(isFlightBookingOptionActive$ | async) && (displayedVersion > discoveryVersionsEnum.V1)"
                                                          [(ngModel)]="data.isFlightStepsEnabled"
                                                          name="isFlightStepsEnabled"
                                                          [options]="discoveryPropertySettingOptions">
                                            <ft-edit-panel label="Flight steps">
                                                <ng-container *ngFor="let item of discoveryPropertySettingOptions">
                                                    <div *ngIf="item.value === data.isFlightStepsEnabled" [innerHTML]="item.label | ftPlaceholder">
                                                    </div>
                                                </ng-container>
                                            </ft-edit-panel>
                                        </ft-select-editor>
                                        <ng-container *ngIf="displayedVersion > discoveryVersionsEnum.V1">
                                            <ft-select-editor title="Display stories in timeline"
                                                              [(ngModel)]="data.isGuestStoriesFallbackEnabled"
                                                              name="isGuestStoriesFallbackEnabled"
                                                              [options]="discoveryPropertySettingOptions">
                                                <ft-edit-panel label="Display stories in timeline"
                                                               summary="Show guest stories alongside selected dates on the linear calendar if there are no experiences to display. If no guest stories are available, property photos will be displayed instead.">
                                                    <ng-container
                                                        *ngFor="let item of discoveryPropertySettingOptions">
                                                        <div *ngIf="item.value === data.isGuestStoriesFallbackEnabled" [innerHTML]="item.label | ftPlaceholder">
                                                        </div>
                                                    </ng-container>
                                                </ft-edit-panel>
                                            </ft-select-editor>
                                        </ng-container>
                                        <ft-toggle-editor name="isRoomsEnabled"
                                                          title="Rooms field"
                                                          summary="Planners will be asked how many rooms they want."
                                                          label="Status"
                                                          [(ngModel)]="data.isRoomsEnabled">
                                            <ft-edit-panel label="Rooms field">
                                                <div>
                                                    {{ data.isRoomsEnabled | ftOnOffToggle }}
                                                </div>
                                            </ft-edit-panel>
                                        </ft-toggle-editor>
                                        <ft-custom-editor title="Communications Point Person for Discovery"
                                                          summary="Choose a team member to be the friendly face for your org when communicating with travelers on websites and email.">
                                            <ft-edit-panel label="Communications Point Person for Discovery">
                                                <ng-container *ngFor="let user of (users$ | async)">
                                                    <ng-container
                                                        *ngIf="data?.signatureCompanyUserUuid === user?.CompanyUserUuid">
                                                        <div [innerHTML]="(data.isCommunicationPointPersonTitleVisible ? user?.displayName : user?.FullName) | ftPlaceholder">
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ft-edit-panel>
                                            <div content>
                                                <ng-select class="ft2-select -large mb-3"
                                                           [(ngModel)]="data.signatureCompanyUserUuid"
                                                           [placeholder]="'Select person'"
                                                           [name]="'signatureCompanyUserUuid'"
                                                           [clearable]="false"
                                                           [searchable]="false"
                                                           [items]="users$ | async"
                                                           [bindValue]="'CompanyUserUuid'"
                                                           [bindLabel]="'displayName'">
                                                </ng-select>
                                                <div class="ft2-input -large v-center h-between"
                                                     *ngIf="isCommunicationUserSelected(data)">
                                                    <label>Always display title</label>
                                                    <mat-slide-toggle
                                                        name="isCommunicationPointPersonTitleVisible"
                                                        [(ngModel)]="data.isCommunicationPointPersonTitleVisible">
                                                    </mat-slide-toggle>
                                                </div>
                                            </div>
                                        </ft-custom-editor>

                                    </ng-container>
                                </form>
                            </div>
                        </section>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ft-modal-wrapper>
