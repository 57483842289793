import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DiscoveryState } from './discovery.state';
import { DiscoveryVisibility } from 'account-hybrid/features/mission-control/enums';
import { selectIsFliptoStaff } from 'account-hybrid/store/authentication.selectors';
import { ExperimentUtilClass } from 'account-hybrid/features/discovery/classes/experiment-util.class';

export const getDiscoveryState = createFeatureSelector<DiscoveryState>('discovery');


// experiments

export const getExperiments = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return !!state ? state.experiments : [];
});

export const getAllExperiments = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return !!state ? state.allExperiments : {};
});

export const getComponentGroupExperimentData = (uuid: string) => createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return !!state ? state.allExperiments[uuid] : [];
});

export const getSelectedComponentGroupExperimentData = createSelector(
    getDiscoveryState,
    state => state.experiments?.reduce((a, b) => a?.start_date < b?.start_date ? a : b, null)
);

export const isExperimentsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.experimentsLoading);

export const getExperimentError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.experimentsError);

export const isAllExperimentsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.allExperimentsLoading);

export const getAllExperimentsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.allExperimentsError);


// stats

export const getExperimentStats = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return state && state.stats;
});

export const isExperimentStatsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.statsLoading);

export const getExperimentStatsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.statsError);


// performance stats

export const getPerformanceStats = createSelector(getDiscoveryState, state => {
    return state.performanceStats;
});

export const isPerformanceStatsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.performanceStatsLoading);

export const getPerformanceStatsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.performanceStatsError);


// complete performance stats

export const getCompletePerformanceStats = createSelector(getDiscoveryState, (state: DiscoveryState, { uuid }) => {
    return state.completePerformanceStats;
});

export const isCompletePerformanceStatsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.completePerformanceLoading);

export const getCompletePerformanceStatsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.completePerformanceError);

// conversion report

export const selectConversionReport = createSelector(getDiscoveryState, (state: DiscoveryState) => state.conversionReport);
export const selectConversionReportIsLoading = createSelector(getDiscoveryState, (state: DiscoveryState) => state.conversionReportIsLoading);
export const selectConversionReportError = createSelector(getDiscoveryState, (state: DiscoveryState) => state.conversionReportError);

// date range

export const getDateRange = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return state.experimentDateRange;
});

// new discovery

export const getTimelineStats = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return state.timelineStats;
});

export const isTimelineStatsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.timelineStatsLoading);

export const getTimelineStatsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.timelineStatsError);


export const getPlannerStats = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return state.plannerStats;
});

export const isPlannerStatsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.plannerStatsLoading);

export const getPlannerStatsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.plannerStatsError);

export const getPlannerStatsYTD = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return state.plannerStatsYTD;
});

export const isPlannerStatsYTDLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.plannerStatsYTDLoading);

export const getPlannerStatsYTDError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.plannerStatsYTDError);

export const getYTDDateRange = createSelector(getDiscoveryState,
    state => state.dateRangeYTD
);

export const getTrafficStats = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return state.trafficStats;
});

export const isTrafficStatsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.trafficStatsLoading);

export const getTrafficStatsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.trafficStatsError);


export const getEmailStats = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return state.emailStats;
});

export const isEmailStatsLoading = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.emailStatsLoading);

export const getEmailStatsError = createSelector(getDiscoveryState,
    (state: DiscoveryState) => state && state.emailStatsError);

export const selectDiscoveryVersioning = createSelector(
    getDiscoveryState,
    state => state?.versioning
);
export const selectIsNewDiscoveryVersionVisible = createSelector(
    selectDiscoveryVersioning,
    selectIsFliptoStaff,
    (versioning, isFliptoStaff) => {
        if (versioning) {
            const isVisible = versioning.discoveryVisibility === DiscoveryVisibility.Everyone || ((versioning.discoveryVisibility === DiscoveryVisibility.FliptoStaff) && isFliptoStaff);
            return isVisible && versioning.isUpgradeAvailable;
        }
        return false;
    }
);
export const selectCurrentDisplayedDiscoveryVersion = createSelector(
    getDiscoveryState,
    state => state?.currentDisplayedVersion
);
export const selectDiscoveryThemingOptions = createSelector(
    getDiscoveryState,
    state => state?.discoveryThemingOptions
);

export const selectDiscoveryHasActiveExperiment = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return Object.keys(state.allExperiments || {}).some(uuid => !!ExperimentUtilClass.getLatestAvailableExperimentData(state.allExperiments[uuid]));
});

export const selectDiscoveryActiveExperimentsMap = createSelector(getDiscoveryState, (state: DiscoveryState) => {
    return Object.keys(state.allExperiments || {}).reduce((acc, uuid) => {
        acc[uuid] = !!ExperimentUtilClass.getLatestAvailableExperimentData(state.allExperiments[uuid]);
        return acc;
    }, {});
});

