export enum Permissions {
    // No permissions, not even generic stats
    None = 0,

    // Access to the advocacy section of the platform
    //  - Overview stats
    AdvocacyRead = 1 << 0,
    //  - Campaigns
    //  - Touchpoints
    AdvocacyWrite = 1 << 1,

    // Access to the stories section of the platform
    // - Library Photos
    // - Library Quotes
    StoriesRead = 1 << 2,
    // - Curation
    StoriesWrite = 1 << 3,

    // Access to the people section of the platform
    // - Guest Search
    // - Guest Timelines
    // - Guest Details/Contact
    PeopleRead = 1 << 4,
    PeopleWrite = 1 << 5,

    // Access to run reports
    Reports = 1 << 6,

    // Access to incentives section of settings
    // - Seperate from other settings as it's a direct cost to hotels
    Incentives = 1 << 7,

    // Access to the settings section of the platform
    // - Details
    // - Branding
    // - Notifications
    GeneralSettingsRead = 1 << 8,
    GeneralSettingsWrite = 1 << 9,

    // Access to CRUD hotelier users on the platform
    // - v0 only at the moment :(
    UserManagementRead = 1 << 10,
    UserManagementWrite = 1 << 11,

    // Access to advanced settings
    // - Company Settings
    // - Languages
    // - Demos
    // - Privacy Policy Component
    // - Privacy
    // - Property Creation
    AdvancedSettingsRead = 1 << 12,
    AdvancedSettingsWrite = 1 << 13,

    // Access to the domains section of company settings
    // - CNAME CRUD
    DomainsRead = 1 << 14,
    DomainsWrite = 1 << 15,

    // Eventual replacement for "isFliptoAdmin"
    FliptoAdministration = 1 << 16,

    // Flag denoting whether PII should be revealed
    // - People Search
    // - Timeline Details
    PersonallyIdentifiableInformation = 1 << 17,

    // General "overview" and "summary" statistics
    Statistics = 1 << 18,

    // access to collaborative space
    Collaborative = 1 << 19,

    // access to sponsor reports inside collaborative space
    CollaborativeSponsorReports = 1 << 20,

    // r/w access to marketing campaigns inside collaborative space
    CollaborativeMarketingCampaigns = 1 << 21,

    // read access to discovery configuration
    DiscoveryRead = 1 << 22,

    // edit rights to discovery configuration
    DiscoveryWrite = 1 << 23,

    /// <summary>
    /// allow reading discovery PII data
    /// PERMISSION CHECK IN STORED PROC!
    /// </summary>
    DiscoveryDataAccess = 1 << 24,

    /// <summary>
    /// allow reading advocacy PII data
    /// PERMISSION CHECK IN STORED PROC!
    /// </summary>
    AdvocacyDataAccess = 1 << 25,

    ExperienceRead = 1 << 26,

    ExperienceWrite = 1 << 27,

    CollaborativeManagement = 1 << 28,

    // Hotelier read-only access
    ReadOnly = AdvocacyRead | StoriesRead | PeopleRead | Reports | GeneralSettingsRead | PersonallyIdentifiableInformation | Statistics | ExperienceRead | DiscoveryRead,

    // Hotelier low-level access
    AccountUser = ReadOnly | AdvocacyWrite | StoriesWrite | PeopleWrite | GeneralSettingsWrite | Incentives | ExperienceWrite | DiscoveryWrite | DiscoveryDataAccess | AdvocacyDataAccess,

    // Hotelier high-level access to everything
    SuperUser = AccountUser | UserManagementRead | UserManagementWrite | AdvancedSettingsRead | AdvancedSettingsWrite | DomainsRead | DomainsWrite | DiscoveryDataAccess | AdvocacyDataAccess,

    // sponsor access to collaborative
    CollaborativeSponsorRole = Collaborative | CollaborativeSponsorReports | CollaborativeMarketingCampaigns | AdvancedSettingsRead | GeneralSettingsRead,

    // high level access to collaborative
    CollaborativeLead = CollaborativeSponsorRole | CollaborativeManagement,

    // God powers
    All = SuperUser | FliptoAdministration |  CollaborativeLead
}
