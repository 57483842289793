import { Experiment } from '../models/experiment.model';

export class ExperimentUtilClass {
    static getLatestAvailableExperimentData(experiments: Experiment[]): Experiment[] {
        // Group experiments that start with percent 0
        const groups: Experiment[][] = [];
        let currentGroup: Experiment[] = [];

        // Split experiment to groups
        for (const experiment of (experiments || [])) {
            if (experiment.percentage[0] === 0) {
                if (currentGroup.length > 0) {
                    groups.push(currentGroup);
                }
                currentGroup = [experiment]; // Start new group
            } else {
                currentGroup.push(experiment); // Continue current group
            }
        }

        // Push the last group if it has elements
        if (currentGroup.length > 0) {
            groups.push(currentGroup);
        }

        // Find the latest group with more than one element and percent is > 0 and < 100 in this group
        return groups.reverse().find(group =>
            group.length > 1 && group.some(experiment => experiment.percentage[0] > 0 && experiment.percentage[0] < 100),
        );
    }
}
