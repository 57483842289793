import {
    Input,
    Component,
    OnChanges,
    SimpleChanges,
    ElementRef,
} from '@angular/core';
import { IdentityService } from "account-hybrid/core/authentication/services/identity.service";
import { Permissions } from "account-hybrid/core/authentication";

@Component({
    selector: '[ftPermissions],ftPermissions',
    template: `<ng-content *ngIf="isVisible"></ng-content>`
})
export class PermissionsComponent implements OnChanges {
    @Input() permissions: Permissions;
    isVisible = false;

    @Input() hide = true;

    constructor(private identity: IdentityService,
        private elem: ElementRef) {
    }
    ngOnChanges(changes: SimpleChanges): void {
        const hasPermission = this.identity.hasPermission(this.permissions);
        if (this.hide) {
            this.setDisabled(false);
            this.isVisible = hasPermission;
        } else {
            this.setDisabled(!hasPermission);
            this.isVisible = true;
        }
    }

    setDisabled(isDisabled) {
        const elem = (this.elem.nativeElement as HTMLElement);
        elem.style.pointerEvents = isDisabled ? 'none' : '';
        if (isDisabled) {
            elem.setAttribute('disabled', 'disabled');
        } else {
            elem.removeAttribute('disabled');
        }
    }

    @Input()
    set ftPermissions(val: keyof typeof Permissions) {
        this.permissions = Permissions[val];
        // const hasPermission = this.identity.hasPermission(this.permissions);
        // if(this.hide) {
        //     this.isDisabled = false;
        //     this.isVisible = hasPermission;
        // } else {
        //     this.isDisabled = hasPermission;
        //     this.isVisible = true;
        // }
    }
}

