import { LicenseService } from '../../../../../app/core/license.service';

export class LicenseCheckHook {

    static $inject = ["$transitions", "LicenseService"];

    constructor($transitions, licenseService: LicenseService) {

        $transitions.onBefore({
            to: function (state) {
                return state.data && state.data.requiredLicense;
            }
        }, function (transition) {
            const licenseRequired = transition.to().data.requiredLicense;
            if (licenseService.checkLicense(licenseService.getCurrentSpaceLicenses(), licenseRequired)) {
                return true;
            } else {
                transition.abort();
                transition.router.stateService.go("hub", transition.params());
            }
        });

    }
}
