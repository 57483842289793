<!-- integration component here.-->
<ng-container *ngIf="integrationViewModel | clone as data">
    <ft-snapshot-form [useFlattenStrategy]="true" (commit)="commit($event)" [loading$]="isLoading$" [commitSuccess$]="commitSuccess$">
        <section class="ft2-block -small">
            <div class="ft2-container">
                <form ngForm>
                    <ng-container *ngIf="languages$ | async as languages">
                        <ft-language-preview-panel [languages]="languages"
                                                   *ngIf="languages.length > 1"
                                                   [selectedLanguage]="selectedPreviewLanguage"
                                                   (previewLanguageChange)="onPreviewLanguageChange($event)"
                                                   [simplified]="true">
                        </ft-language-preview-panel>
                    </ng-container>
                    <!-- Traffic Percentage.-->
                    <ft-input-editor [title]="'Traffic Percentage'"
                                     summary="Set the percentage of traffic that should see Discovery."
                                     type="number"
                                     [name]="'trafficPercentage'"
                                     [min]="0"
                                     [max]="100"
                                     [(ngModel)]="data.trafficPercentage">
                        <ft-edit-panel label="Traffic Percentage" [loading]="isLoading$ | async">
                            <div [innerHTML]="data.trafficPercentage + '%' | ftPlaceholder"></div>
                        </ft-edit-panel>
                    </ft-input-editor>
                    <!-- End Traffic Percentage.-->



                    <ng-container *ngIf="data | languageData: 'languageData' as languages">
                        <ng-container *ngFor="let item of languages.languageData; let i = index;">
                            <!-- Booking Engine Template Url.-->
                            <ft-textarea-editor *ngIf="(componentGroupType$ | async) !== 2 && isCompanySpaceType && item.languageCode === 'en'"
                                                [title]="'Booking Engine Template Url'"
                                                summary="This is where Discovery should send users to complete their booking."
                                                [tokens]="bookingEngineUrlTokens"
                                                [name]="'languageData[' + i +'].bookingEngineTemplateUrl'"
                                                [type]="'url'"
                                                [required]="false"
                                                [(ngModel)]="item.bookingEngineTemplateUrl">
                                <ft-edit-panel label="Booking Engine Template Url" [loading]="isLoading$ | async">
                                    <div [innerHTML]="item.bookingEngineTemplateUrl | ftPlaceholder"></div>
                                </ft-edit-panel>
                            </ft-textarea-editor>
                        </ng-container>
                    </ng-container>

<!--                        Commented out section due to future updates in language variations approach -->
<!--                        <ft-multiple-textarea-editor title="Booking Engine URL template"-->
<!--                                                     *ngIf="(componentGroupType$ | async) !== 2 && !isCompanySpaceType"-->
<!--                                                     summary="This is where Discovery should send users to complete their booking."-->
<!--                                                     [tokens]="bookingEngineUrlTokens"-->
<!--                                                     name="languageData"-->
<!--                                                     [languages]="languages$ | async"-->
<!--                                                     [languageVariationsView]="true"-->
<!--                                                     [fields]="[{ name: 'bookingEngineTemplateUrl', languageName: 'languageName' }]"-->
<!--                                                     [(ngModel)]="data.languageData">-->
<!--                            <ft-edit-panel label="Booking Engine URL template">-->
<!--                                <ng-container *ngFor="let language of data?.languageData">-->
<!--                                    <ng-container-->
<!--                                        *ngIf="language?.languageCode === selectedPreviewLanguage?.languageCode">-->
<!--                                        <div>-->
<!--                                            {{ language?.bookingEngineTemplateUrl | ftPlaceholder }}-->
<!--                                        </div>-->
<!--                                    </ng-container>-->
<!--                                </ng-container>-->
<!--                            </ft-edit-panel>-->
<!--                        </ft-multiple-textarea-editor>-->


                    <!-- End Engine Template Url.-->

                    <!-- Code.-->
                    <ft-input-editor [title]="'Discovery code'"
                                     summary="This is the unique identifier for Discovery and should match the code that is installed on the website."
                                     [name]="'code'"
                                     [required]="true"
                                     maxlength="35"
                                     [(ngModel)]="data.code">
                        <ft-edit-panel label="Discovery code" [loading]="isLoading$ | async">
                            <div [innerHTML]="data.code | ftPlaceholder"></div>
                        </ft-edit-panel>
                    </ft-input-editor>
                    <!-- End Code.-->

                    <ft-custom-editor [title]="'Load timing'"
                                      summary="Change how Discovery loads onto the page, based on the performance of this site integration.
                                                To reduce impact on Core Web Vitals (for Google SEO), use “Load Discovery after page completes loading”.
                                                To eliminate impact entirely from speed measurement tools (Lighthouse, PageSpeed, etc.), add a delay of at least 5000 milliseconds.
                                                * Note that these settings may degrade the speed that Discovery initially opens in favor of improved overall page performance.">
                        <ft-edit-panel label="Load timing" [loading]="isLoading$ | async">
                            <div *ngIf="data.facadeDelayInMs === -1">
                                Load Discovery immediately when page starts loading
                            </div>
                            <div *ngIf="data.facadeDelayInMs === 0 || !data.facadeDelayInMs">
                                Load Discovery after page completes loading
                            </div>
                            <div *ngIf="data.facadeDelayInMs > 0">
                                Load Discovery {{ data.facadeDelayInMs }} milliseconds after page completes loading
                            </div>
                        </ft-edit-panel>
                        <div content>
                            <div class="ft2-form-group">
                                <ng-select class="ft2-select -large"
                                           [clearable]="false"
                                           [bindLabel]="'label'"
                                           [bindValue]="'value'"
                                           [placeholder]="'Select loading strategy'"
                                           (change)="onFacadeStrategyChange($event, data)"
                                           [ngModelOptions]="{standalone: true}"
                                           [ngModel]="data.facadeDelayInMs < 0">
                                    <ng-option [value]="true">Load Discovery immediately when page starts loading
                                    </ng-option>
                                    <ng-option [value]="false">Load Discovery after page completes loading
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="ft2-form-group"
                                 [hidden]="data.facadeDelayInMs < 0">
                                <label>Delay (milliseconds)</label>
                                <input class="ft2-input -large mt-2"
                                       name="facadeDelayInMs"
                                       [min]="-1"
                                       [max]="10000"
                                       [(ngModel)]="data.facadeDelayInMs"
                                       type="number"
                                       placeholder="Delay (in milliseconds)">
                            </div>
                        </div>
                    </ft-custom-editor>
                    <ft-toggle-editor name="isBrowserBackButtonNavActive"
                                      [(ngModel)]="data.isBrowserBackButtonNavActive"
                                      label="Status"
                                      title="Allow Discovery to use browser’s back button"
                                      summary="Discovery will attempt to step backwards through the flow if the browser’s back button is clicked. On websites built as a single page application, this feature may not always work as intended depending on how the website manages browser history states.">
                        <ft-edit-panel label="Allow Discovery to use browser’s back button">
                            {{ data.isBrowserBackButtonNavActive | ftOnOffToggle }}
                        </ft-edit-panel>
                    </ft-toggle-editor>
                </form>
            </div>
        </section>
    </ft-snapshot-form>
</ng-container>
