<ft-modal-wrapper>
  <ft-touchpoint-header [name]="title" [activeToggleEnabled]='false'>
  </ft-touchpoint-header>
  <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms">
    <mat-tab label="Integration">
      <ng-template matTabContent>
        <ft-permissions [ftPermissions]="'DiscoveryWrite'" [hide]="false">
          <ng-container *ngIf="emailSettings$ | async | clone as data">
            <ft-snapshot-form (commit)="commit({emailSchedule: $event})" [loading$]="isLoading$" [commitSuccess$]="commitSuccess$">
              <section class="ft2-block -small">
                <div class="ft2-container">
                  <form ngForm>
                    <!-- Re-engagement Email schedule.-->
                    <ft-input-editor [title]="'Re-engagement Email schedule'"
                                     *ngIf="!isReminderEmail"
                                     summary="Set the number of minutes to wait before sending this email. We recommend 30 minutes for the initial email. This email will not be sent if the planner completes their booking before this time." type="number"
                      [name]="'initialDelayInMinutes'" [(ngModel)]="data.initialDelayInMinutes">
                      <ft-edit-panel label="Re-engagement Email schedule" [loading]="isLoading$ | async">
                        <div
                          [innerHTML]="'Send after ' + data.initialDelayInMinutes + ' minutes after planner unveils' | ftPlaceholder">
                        </div>
                      </ft-edit-panel>
                    </ft-input-editor>
                      <ft-input-editor [title]="'Re-engagement Email reminder schedule'"
                                       *ngIf="isReminderEmail"
                                       summary="Set the number of minutes to wait before sending this email. We recommend 1440 minutes (24 hours) for the reminder email. This email will not be sent if the planner completes their booking before this time." type="number"
                                       [name]="'reminderDelayInMinutes'" [(ngModel)]="data.reminderDelayInMinutes">
                          <ft-edit-panel label="Re-engagement Email reminder schedule" [loading]="isLoading$ | async">
                              <div
                                  [innerHTML]="isEmailDisabled(data.reminderDelayInMinutes) ? 'Disabled' : 'Send after ' + data.reminderDelayInMinutes + ' minutes after planner unveils' | ftPlaceholder">
                              </div>
                          </ft-edit-panel>
                      </ft-input-editor>
                    <!-- End Re-engagement Email schedule.-->
                  </form>
                </div>
              </section>
            </ft-snapshot-form>
          </ng-container>
        </ft-permissions>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
